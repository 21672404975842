// ========================================================
// Main navigation
// ========================================================
var Navigation = function(settings) {
	this.settings = extend({}, this.defaultSettings, settings);
	this.container = document.querySelector('.menu');
	this.links = [];
	this.activeLink;

	var links = this.container.querySelectorAll('.js-menu-link');
	for (var i = 0, l = links.length; i < l; i++) {
		new NavigationLink(links[i], 'main', this);
	}

	/* Mobile background menu */
	var backgroundMenu = document.querySelector('.background-menu');
	var backgroundLinks = backgroundMenu.querySelectorAll('.js-menu-link');
	for (var i = 0, l = backgroundLinks.length; i < l; i++) {
		new NavigationLink(backgroundLinks[i], 'background', this);
	}
}

Navigation.prototype.defaultSettings = {
	'onOpen': function() {},
	'onClose': function() {}
}

var NavigationLink = function(node, type, parent) {
	this.node = node;
	this.type = type;
	this.navigation = parent;
	this.activeClass = 'menu--item-active';
	this.submenu = this.node.parentElement;
	this.node.addEventListener('click', this.click.bind(this));

	if (this.submenu) {
		var returnBtn = this.submenu.querySelector('button');
		if (returnBtn) returnBtn.addEventListener('click', this.close.bind(this));
	}
}

NavigationLink.prototype.click = function(e) {
	if (this.submenu) {
		e.preventDefault();
		this.toggle();
	}
}

NavigationLink.prototype.toggle = function() {
	if (this.submenu.classList.contains(this.activeClass)) {
		this.close();
	} else {
		this.open();
	}
}

NavigationLink.prototype.open = function() {
	if (this.navigation.activeLink) {
		this.navigation.activeLink.close();
	}
	this.submenu.classList.add(this.activeClass);
	this.navigation.activeLink = this;
	this.navigation.settings.onOpen.apply(this, arguments);
}

NavigationLink.prototype.close = function() {
	this.submenu.classList.remove(this.activeClass);
	this.navigation.activeLink = null;
	this.navigation.settings.onClose.apply(this, arguments);
}