// ========================================================
// Lightbox
// ========================================================
/*
<a href="#" class="js-lightbox-trigger">lightbox trigger</a>

<div class="js-lightbox lightbox">
    <div class="lightbox__table">
        <div class="lightbox__cell">
            <div class="lightbox__content">
            </div>
        </div>
    </div>
</div>
*/
var Lightbox = function(node, settings) {
    this.settings = extend({}, this.defaultSettings, settings);
    this.container = node;
    this.content = this.container.querySelector(this.settings.content);
    this.trigger = document.querySelectorAll(this.settings.trigger);
    this.closeBtn = this.container.querySelectorAll(this.settings.closeBtn);
    this.background = this.container.querySelector(this.settings.background);
    this.triggerClicked;

    this.triggers = [];
    for (var i = 0; i < this.trigger.length; i++) {
    	this.triggers.push(new LightboxTrigger(i, this.trigger[i], this));
    }

    this.keyup = this.keyup.bind(this);

    for (var i = 0; i < this.closeBtn.length; i++) {
        this.closeBtn[i].addEventListener('click', this.close.bind(this));
    }
    this.background.addEventListener('click', this.close.bind(this));
}

Lightbox.prototype.defaultSettings = {
    'trigger': '.js-lightbox-trigger',
    'closeBtn': '.js-lightbox-close',
    'content': '.lightbox__content',
    'activeClass': 'lightbox--active',
    'background': '.lightbox__bg',
    'onOpen': function() {},
    'onClose': function() {}
}

Lightbox.prototype.open = function(e) {
	if (e) e.preventDefault();
    this.container.classList.add(this.settings.activeClass);
    this.settings.onOpen.apply(this, arguments);
    document.addEventListener('keyup', this.keyup);
}

Lightbox.prototype.close = function(e) {
    if (e) e.preventDefault();
    this.container.classList.remove(this.settings.activeClass);
    this.settings.onClose.apply(this, arguments);
    this.triggerClicked = null;
    document.removeEventListener('keyup', this.keyup);
}

Lightbox.prototype.keyup = function(e) {
    if (e.keyCode == 27) {
        this.close();
    }
}

Lightbox.prototype.loadContent = function(url) {
    ajax({
        'url': url,
        'method': 'POST',
        'success': function() {
            this.settings.showContent.apply(this, arguments);
        }.bind(this),
        'error': function(message) {
            console.error(message);
        }
    });
}

var LightboxTrigger = function(index, trigger, parent) {
    this.index = index;
    this.node = trigger;
    this.url = this.node.href || this.node.getAttribute('data-href');
    this.lightbox = parent;

    this.node.addEventListener('click', this.click.bind(this));
}

LightboxTrigger.prototype.click = function(e) {
    e.preventDefault();
    this.lightbox.triggerClicked = this.node;
    this.lightbox.open();
}