// ========================================================
// BACKGROUND MENU
// ========================================================
var BackgroundMenu = function(settings) {
	this.settings = settings;
	this.page = document.querySelector('.page');
	this.content = this.page.querySelector('.page__content');
	this.menu = this.page.querySelector('.menu');
	var toggleBtns = document.querySelectorAll('.js-menu-toggle');

	this.isVisible = false;
	this.close = this.close.bind(this);

	for (var i = 0; i < toggleBtns.length; i++) {
		toggleBtns[i].addEventListener('click', this.toggle.bind(this), false);
	}
}

BackgroundMenu.prototype.toggle = function() {
	if (!this.isVisible) {
		this.scrollPosition = this.getScrollTop();
		this.open();
	} else {
		this.close();
	}
}

BackgroundMenu.prototype.getScrollTop = function() {
    if (typeof pageYOffset!= 'undefined') {
        //most browsers except IE before #9
        return pageYOffset;
    } else{
        var B= document.body; //IE 'quirks'
        var D= document.documentElement; //IE with doctype
        D= (D.clientHeight)? D: B;
        return D.scrollTop;
    }	
}

BackgroundMenu.prototype.open = function() {
	this.content.style.height = '100vh';
	this.content.style.overflow = 'auto';
	//this.menu.style.position = 'absolute'; // IE fix

	this.content.scrollTop = this.scrollPosition;
	window.scrollTo(0, 0);

	this.isVisible = true;

	if (this.settings.onOpen) {
		this.settings.onOpen.apply(this, arguments);
	}
}

BackgroundMenu.prototype.close = function() {
	setTimeout(function() {
		this.content.style.height = '';
		this.content.style.overflow = '';
		//this.menu.style.position = '';

		window.scrollTo(0, this.scrollPosition);
	}.bind(this), 400);

	this.isVisible = false;

	if (this.settings.onClose) {
		this.settings.onClose.apply(this, arguments);
	}
}