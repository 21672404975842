// ========================================================
// Carousel Slider
// ========================================================
var CarouselSlider = function(node, settings) {
	this.settings = extend({}, this.defaultSettings, settings);
	this.container = node;
	this.list = this.container.querySelector(this.settings.list);
	this.prevBtn = this.container.querySelector(this.settings.prevBtn);
	this.nextBtn = this.container.querySelector(this.settings.nextBtn);
	this.items = [];

	var items = this.list.children;
	for (var i = 0, l = items.length; i < l; i++) {
		this.items.push(new CarouselSliderItem(items[i], i));
	}

	this.moveBy = this.settings.moveBy;  
	this.calculateScrollOffset();

	this.position = 0;
	this.marginOffset = 8; // Is needed to fix the scrollLength inconsistency
	this.scrollLength = (this.list.scrollWidth - this.list.offsetWidth) - this.marginOffset;
	this.scroll = this.scroll.bind(this);
	this.resize = this.resize.bind(this);

	this.prevBtn.addEventListener('click', this.prev.bind(this));
	this.nextBtn.addEventListener('click', this.next.bind(this));
	this.list.addEventListener('scroll', this.scroll);
	window.addEventListener('resize', this.resize);
	this.scroll();
	this.slide();
}

CarouselSlider.prototype.defaultSettings = {
	'moveBy': 1, // Skip the amount of items on a next or prev event
	'list': 'ul',
	'prevBtn': '.js-prev',
	'nextBtn': '.js-next'
}

CarouselSlider.prototype.resize = function() {
	this.scrollLength = (this.list.scrollWidth - this.list.offsetWidth) - this.marginOffset;
	this.calculateScrollOffset();
	this.scroll();
}

CarouselSlider.prototype.calculateScrollOffset = function() {
	this.listWidth = this.list.scrollWidth;
	this.scrollOffset = (this.listWidth / this.items.length) * this.moveBy;
}

CarouselSlider.prototype.prev = function() {
	this.position = -this.scrollOffset;
	this.slide();
}

CarouselSlider.prototype.next = function() {
	this.position = this.scrollOffset;
	this.slide();
}

CarouselSlider.prototype.slide = function() {
    var list = this.list;
	var start = list.scrollLeft;
	var timeStart;
    var timeElapsed;
    var distance = this.position;
    var duration = 400;

    requestAnimationFrame(function(time) { 
    	timeStart = time; 
    	loop(time); 
    });

    function loop(time) {
        timeElapsed = time - timeStart;
        list.scrollLeft = easeInOutQuad(timeElapsed, start, distance, duration);
        if (timeElapsed < duration) {
            requestAnimationFrame(loop);
        }
    }

    // Robert Penner's easeInOutQuad - http://robertpenner.com/easing/
    function easeInOutQuad(t, b, c, d)  {
        t /= d / 2
        if(t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
    }
}

CarouselSlider.prototype.scroll = function() {
	if (this.list.scrollLeft === 0 && this.scrollLength < 0) {
		this.prevBtn.style.display = 'none';
		this.nextBtn.style.display = 'none';
	} else if (this.list.scrollLeft === 0) {	
		this.prevBtn.style.display = 'none';
		this.nextBtn.style.display = '';
	} else if (Math.ceil(this.list.scrollLeft) >= this.scrollLength) {
		this.prevBtn.style.display = '';
		this.nextBtn.style.display = 'none';
	} else {
		this.prevBtn.style.display = '';
		this.nextBtn.style.display = '';
	}
}

var CarouselSliderItem = function(node, index) {
	this.node = node;
	this.index = index;
}