// ========================================================
// Paralax
// ========================================================
var Paralax = function(node, settings) {
	this.ticker = node;

	this.resize = this.resize.bind(this);
	this.scroll = this.scroll.bind(this);

	window.addEventListener('resize', this.resize);
	window.addEventListener('scroll', this.scroll);

	this.resize();	
}

Paralax.prototype.resize = function() {
	clearTimeout(this.resizeTimer);
	this.resizeTimer = setTimeout(function() {
		this.tickerOffset = this.ticker.getBoundingClientRect().top;
		this.windowH = window.innerHeight;
		this.scrollPos = this.windowH + window.pageYOffset;
		this.scroll();
	}.bind(this), 100);
}

Paralax.prototype.scroll = function() {
	this.scrollPos = this.windowH + window.pageYOffset;

	if (this.scrollPos / this.tickerOffset >= 1) {
		this.ticker.style.transform = 'translateY(-'+((this.scrollPos-this.tickerOffset)/8)+'px)';
	}
}
