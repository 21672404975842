/*
@CSS ========================================================
.carousel {
}

.carousel__inner {
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto; 
    height: 100vh;
}

.carousel__item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    transform: scale(1) rotate(25deg) translateY(100%);
    background-color: red;
    z-index: 1;
    opacity: 0;
    transform-origin: left top;
    transition: $time ease-in-out;
    transition-property: transform, left, top, opacity;
}

.carousel__dot-list .carousel__item--active {
    background-color: red;
    transition: background-color $time ease-in-out;
}

.carousel__text {
    opacity: 0;
}

.carousel__images img {
    opacity: 0;
}

.carousel__item--active {
    z-index: 3;  
    opacity: 1;
    transform: scale(1) rotate(0) translateY(0);
}

.carousel__item--active + .carousel__item,
.carousel-item--lineup-first {
    background-color: blue;
    top: -15px;
    left: 12px;
    transform: scale(0.98) rotate(-1deg) translateY(0);
    opacity: .5;
    z-index: 2;
}

.carousel__item--active + .carousel__item + .carousel__item,
.carousel-item--lineup-second {
    background-color: green;
    top: -30px;
    left: 25px;
    transform: scale(0.96) rotate(-2deg) translateY(0);
    opacity: .2;
    z-index: 1;
}

@HTML ========================================================
<div class="carousel js-carousel">
    <button class="js-prev">prev</button>
    <button class="js-next">next</button>
    <ul class="carousel__dot-list"></ul>
    <div class="carousel__inner">
        <div class="carousel__item js-carousel-item">
            <div class="carousel__images">
                <img class="carousel__images--1" src="http://placehold.it/200x200" alt="">
                <img class="carousel__images--2" src="http://placehold.it/200x200" alt="">
            </div>
            <div class="carousel__text">SLIDE 1</div>
        </div>
        <div class="carousel__item js-carousel-item">
            <div class="carousel__images">
                <img class="carousel__images--1" src="http://placehold.it/200x200" alt="">
                <img class="carousel__images--2" src="http://placehold.it/200x200" alt="">
            </div>
            <div class="carousel__text">SLIDE 2</div>
        </div>
        <div class="carousel__item js-carousel-item">
            <div class="carousel__images">
                <img class="carousel__images--1" src="http://placehold.it/200x200" alt="">
                <img class="carousel__images--2" src="http://placehold.it/200x200" alt="">
            </div>
            <div class="carousel__text">SLIDE 3</div>
        </div>
        <div class="carousel__item js-carousel-item">
            <div class="carousel__images">
                <img class="carousel__images--1" src="http://placehold.it/200x200" alt="">
                <img class="carousel__images--2" src="http://placehold.it/200x200" alt="">
            </div>
            <div class="carousel__text">SLIDE 4</div>
        </div>
    </div>
</div>
*/

// ========================================================
// Carousel
// ========================================================
var Carousel = function(node, settings) {
	this.settings = extend({}, this.defaultSettings, settings);
    this.container = node;
	this.dotList = this.container.querySelector(this.settings.dotList);
	this.prevBtn = this.container.querySelectorAll(this.settings.prevBtn);
	this.nextBtn = this.container.querySelectorAll(this.settings.nextBtn);
	this.item = this.container.querySelectorAll(this.settings.item);
	this.isChanging = false;
    this.autoPlayStopped = false;

	this.items = [];
	this.prev = this.prev.bind(this);
	this.next = this.next.bind(this);
	this.index = this.settings.index;

	this.setup();
}

Carousel.prototype.defaultSettings = {
	'item': '.js-carousel-item',
	'activeClass': 'carousel__item--active',
	'prevBtn': '.js-prev',
	'nextBtn': '.js-next',
    'dotList': '.carousel__dot-list',
	'index': 0,
    'autoPlay': 7000,
	'changeDuration': 0,
	'onChangeComplete': function() {},
    'onBeforeChange': function() {}
}

Carousel.prototype.setup = function() {
	for (var i = 0, l = this.item.length; i < l; i++) {
		this.items.push(new CarouselItem(i, this.item[i], this));
	}
	
	for (var i = 0, l = this.prevBtn.length; i < l; i++) {
		this.prevBtn[i].addEventListener('click', this.prev, false);
	}

	for (var i = 0, l = this.nextBtn.length; i < l; i++) {
		this.nextBtn[i].addEventListener('click', this.next, false);
	}

    this.itemsLength = this.items.length;
	this.change(this.index);
    this.autoPlayStart();
}

Carousel.prototype.autoPlayStart = function() {
    if (this.settings.autoPlay && !this.autoPlayStopped) {
        this.autoPlayInterval = setInterval(this.next, this.settings.autoPlay);
    }
}

Carousel.prototype.autoPlayPause = function() {
    if (this.autoPlayInterval) {
        clearInterval(this.autoPlayInterval);
    }
}

Carousel.prototype.autoPlayStop = function() {
    this.autoPlayStopped = true;
    this.autoPlayPause();
}

Carousel.prototype.prev = function(e) {
    if (e && e.type === 'click') {
        this.autoPlayStop();
    }
	this.change(this.index - 1);
}

Carousel.prototype.next = function(e) {
    if (e && e.type === 'click') {
        this.autoPlayStop();
    }
	this.change(this.index + 1);
}

Carousel.prototype.change = function(newIndex) {
	if (this.isChanging) return;

    this.isChanging = true;
    this.previousSlide = this.items[this.index];
    this.clearStatus();
    this.settings.onBeforeChange.apply(this, arguments);
	
	this.index = (newIndex % this.itemsLength + this.itemsLength) % this.itemsLength;

	if (this.index == this.itemsLength - 2) {
		this.items[0].node.classList.add('carousel-item--lineup-second');
	} else if (this.index == this.itemsLength - 1) {
		this.items[0].node.classList.add('carousel-item--lineup-first');
		this.items[1].node.classList.add('carousel-item--lineup-second');
	}
	
    this.setActiveSlide();

	setTimeout(function() {
		this.settings.onChangeComplete.apply(this, arguments);
	}.bind(this), this.settings.changeDuration/4);

	setTimeout(function() {
		this.isChanging = false;
	}.bind(this), this.settings.changeDuration);
}

Carousel.prototype.clearStatus = function() {
    for (var i = 0, l = this.items.length; i < l; i++) {
        this.items[i].node.classList.remove(this.settings.activeClass);
        this.items[i].node.classList.remove('carousel-item--lineup-first');
        this.items[i].node.classList.remove('carousel-item--lineup-second');
        this.items[i].dot.node.classList.remove(this.settings.activeClass);
    }
}

Carousel.prototype.setActiveSlide = function() {
    this.currentSlide = this.items[this.index];
    this.currentSlide.node.classList.add(this.settings.activeClass);
    this.currentSlide.dot.node.classList.add(this.settings.activeClass);
}




var CarouselItem = function(index, node, parent) {
	this.index = index;
	this.node = node;
	this.carousel = parent;
    this.dot = new CarouselDot(index, parent);

    this.node.addEventListener('click', this.click.bind(this), false);
    this.node.addEventListener('mouseover', this.carousel.autoPlayPause.bind(this.carousel), false);
	this.node.addEventListener('mouseleave', this.carousel.autoPlayStart.bind(this.carousel), false);
}

CarouselItem.prototype.click = function() {
    if (this.index !== this.carousel.index) {
        this.carousel.autoPlayStop();
        this.carousel.change(this.index);
    }
}

var CarouselDot = function(index, parent) {
    this.index = index;
    this.carousel = parent;
    var newLi = document.createElement('li');
    this.node = this.carousel.dotList.appendChild(newLi);
    
    this.node.addEventListener('click', this.click.bind(this), false);
}

CarouselDot.prototype.click = function() {
    this.carousel.autoPlayStop();
    this.carousel.change(this.index);
}