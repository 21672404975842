// ========================================================
// FLOATING LABELS
// ========================================================
var FloatingLabel = function(node, settings) {
    this.settings = extend({}, this.defaultSettings, settings);
    
    var container = node;
    var input = container.querySelector('input') || container.querySelector('textarea');
    var label = container.querySelector('label');
    var focusClass = this.settings.focusClass;
    var hasValueClass = this.settings.hasValueClass;

    if (input.value.length) {
        container.classList.add(hasValueClass);
    }

    label.addEventListener('click', function() {
        input.focus();
    });

    input.addEventListener('blur', function() {
        container.classList.remove(focusClass);
        container.classList.toggle(hasValueClass, !!input.value.length);
    });

    input.addEventListener('focus', function() {
        container.classList.add(focusClass);
    });
}

FloatingLabel.prototype.defaultSettings = {
    'focusClass': 'focus',
    'hasValueClass': 'has-value'
}