// ===========================================================
// MODAL
// ===========================================================
function Modal(node, settings) {
	var defaultSettings = {
		'overlay': '.js-modal-overlay',
		'fadeDuration': 400,
		'insideTrigger': true,
		'onOpen': function() {},
		'onClose': function() {}
	}

	var settings = extend({}, defaultSettings, settings);
	var container = node;

	if (!this.overlay) {
		if (!settings.insideTrigger) {
			document.querySelector('.page').insertAdjacentHTML('afterend', '<div class="overlay js-modal-overlay" tabindex="-1"></div>');
		} else {
			container.insertAdjacentHTML('afterend', '<div class="overlay js-modal-overlay" tabindex="-1"></div>');
		}
	}
	var overlay = this.overlay = document.querySelector(settings.overlay);

	if (document.defaultView && document.defaultView.getComputedStyle) {
		var s = document.defaultView.getComputedStyle(overlay, '');
		var zIndex = s.getPropertyValue('z-index');
	} else if(p.currentStyle) {
		var zIndex = overlay.currentStyle['zIndex'];
	}

	zIndex = parseInt(zIndex);
	var fadeDuration = settings.fadeDuration;
	var body = document.body;
	this.timer;
	var positionStyle;

	var close = function(e) {
		if (e && e.type === 'keyup' && e.keyCode !== 27) {
			return;
		}

		document.removeEventListener('keyup', close);
		overlay.removeEventListener('click', close);
		body.classList.remove('modal-active');

		this.timer = setTimeout(function() {
			container.style.zIndex = '';
			container.style.position = '';
			overlay.remove();
			this.overlay = null;
		}.bind(this), fadeDuration);
		settings.onClose.apply(this, arguments);
	}.bind(this);

	var open = function() {
		document.addEventListener('keyup', close);
		clearTimeout(this.timer);
		overlay.addEventListener('click', close);

		if (document.defaultView && document.defaultView.getComputedStyle) {
			var s = document.defaultView.getComputedStyle(container, '');
			positionStyle = s.getPropertyValue('position');
		} else if(p.currentStyle) {
			positionStyle = overlay.currentStyle['position'];
		}

		container.style.zIndex = zIndex + 1;
		if (positionStyle !== 'fixed' && positionStyle !== 'absolute' && positionStyle !== 'relative') {
			container.style.position = 'relative';
		}

		body.classList.add('modal-active');
		settings.onOpen.apply(this, arguments);
	}.bind(this);

	return {
		'open': open,
		'close': close
	}
}