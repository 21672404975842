// ========================================================
// VALIDATE FORM ELEMENTS
// ========================================================
/*
NOTE: To use different validations on the same input type, you can use a data-type.
Usage:
    <input type="text" data-type="zipcode">
    <input type="number" data-type="currency">
*/

/*
NOTE: To submit a form with ajax just switch the boolean to true.
It will submit the form with the given action and method property.
Use the callbacks after the submit has been done.
Usage:
    new FormValidate(document.querySelector(form), {
        'ajax': true,
        'success': function() {},
        'error': function() {}
    });
*/

var FormValidate = function(node, settings) {
    this.settings = extend({}, this.defaultSettings, settings);
    this.form = node;
    this.form.setAttribute('novalidate', 'true');
    this.inputContainers = this.form.querySelectorAll(this.settings.inputContainer);
    this.errorClass = this.settings.errorClass;
    this.ajax = this.settings.ajax;

    this.requiredFields = [];
    for (var i = 0, l = this.inputContainers.length; i < l; i++) {
        
        if (this.inputContainers[i].querySelector('[required]')) {
            this.requiredFields.push(new FormInput(i, this.inputContainers[i], this));
        }
    }

    this.form.addEventListener('submit', this.submit.bind(this));
}

FormValidate.prototype.defaultSettings = {
    'inputContainer': 'fieldset',
    'errorClass': 'error',
    'ajax': true,
    'beforeSend': function() {},
    'success': function() {},
    'error': function() {}
}

FormValidate.prototype.validate = function() {
    this.errors = [];

    for (var i = 0, l = this.requiredFields.length; i < l; i++) {
        var input = this.requiredFields[i];

        if (!input.validate()) {
            this.errors.push(input);
        }
    }

    return !!this.errors.length;
}

FormValidate.prototype.submit = function(e) {
    //e.preventDefault();
    if (this.validate()) {
        e.preventDefault();
        // Stops the form submition
        return false;
    } else {
        // Submits the form natively or with ajax
        if (this.ajax) {
            ajax({
                'url': this.form.action,
                'data': serialize(this.form),
                'type': this.form.method,
                'beforeSend': this.settings.beforeSend,
                'success': this.settings.success,
                'error': this.settings.error
            });   
            e.preventDefault();         
            return false;
        } else {
            return true;
        }
    }
}


var FormInput = function(index, node, parent) {
    this.index = index;
    this.container = node;
    this.formValidate = parent;
    this.errorClass = this.formValidate.errorClass;
    this.input = this.container.querySelector('[required]');
    this.inputType = this.input.nodeName.toLowerCase();

    switch(this.inputType) {
        case 'select':
            this.validate = this.validateSelect;
            this.input.addEventListener('blur', this.validate.bind(this));
            this.input.addEventListener('change', this.validate.bind(this));
        break;

        case 'textarea':
            this.validate = this.validateText;
            this.input.addEventListener('blur', function() {
                setTimeout(this.validateText.bind(this), 0);
            }.bind(this));

            this.input.addEventListener('keydown', function() {
                setTimeout(this.validateText.bind(this), 0);
            }.bind(this));
        break;

        case 'input':
            switch(this.input.type) {
                case 'radio':
                    this.validate = this.validateRadio;
                    this.group = document.querySelectorAll('[name='+this.input.name+']');
                    this.input.addEventListener('blur', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));

                    this.input.addEventListener('change', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));
                break;

                case 'checkbox':
                    this.validate = this.validateCheckbox;
                    this.input.addEventListener('blur', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));

                    this.input.addEventListener('change', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));
                break;

                case 'number':
                    switch(this.input.attr('data-type')) {
                    	/* Custom validator go here */
                        case 'currency':
                            this.validate = this.validateCurrency;
                        break;
                        /* Custom validator go here - End */

                        default:
                            this.validate = this.validateNumber;
                    }
                    this.input.addEventListener('blur', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));

                    this.input.addEventListener('keydown', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));
                break;

                case 'text':
                    switch(this.input.getAttribute('data-type')) {
                    	/* Custom validator go here */
                        case 'zipcode':
                            this.validate = this.validateZipcode;
                        break;
                        /* Custom validator go here - End */

                        default:
                            this.validate = this.validateText;
                    }

                    this.input.addEventListener('blur', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));

                    this.input.addEventListener('keydown', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));
                break;

                case 'email':
                    this.validate = this.validateEmail;
                    this.input.addEventListener('blur', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));

                    this.input.addEventListener('keydown', function() {
                        setTimeout(this.validate.bind(this), 0);
                    }.bind(this));
                break;
            }
        break;
    }
}

FormInput.prototype.isInputVisible = function() {
    if (this.input.offsetParent === null) {
        this.container.classList.remove(this.errorClass);
        return false;
    } else {
        return true;
    }
}

FormInput.prototype.validateSelect = function() {
    if (!this.isInputVisible()) return true;

    var val = this.input.value;

    if (val.length) {
        this.container.classList.remove(this.errorClass);
        return true;
    } else {
        this.container.classList.add(this.errorClass);
        return false;
    }
}

FormInput.prototype.validateZipcode = function() {
    if (!this.isInputVisible()) return true;

    var val = this.input.value;
    // This regex is for dutch zipcodes
    var reg = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

    if (val.length && reg.test(val)) {
        this.container.classList.remove(this.errorClass);
        return true;
    } else {
        this.container.classList.add(this.errorClass);
        return false;
    }
}

FormInput.prototype.validateText = function() {
    if (!this.isInputVisible()) return true;

    var val = this.input.value;

    if (val.length) {
        this.container.classList.remove(this.errorClass);
        return true;
    } else {
        this.container.classList.add(this.errorClass);
        return false;
    }
}

FormInput.prototype.validateRadio = function() {
    if (!this.isInputVisible()) return true;

    var val = false;
    this.group.each(function(index, el) {
        el = $(el);
        if (el.prop('checked')) {
            val = true;
        }
    }.bind(this));

    if (val) {
        this.container.classList.remove(this.errorClass);
        return true;
    } else {
        this.container.classList.add(this.errorClass);
        return false;
    }
}

FormInput.prototype.validateCheckbox = function() {
    if (!this.isInputVisible()) return true;

    var val = this.input.prop('checked');

    if (val) {
        this.container.classList.remove(this.errorClass);
        return true;
    } else {
        this.container.classList.add(this.errorClass);
        return false;
    }
}

FormInput.prototype.validateEmail = function() {
    if (!this.isInputVisible()) return true;

    var val = this.input.value;
    var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (val.length && reg.test(val)) {
        this.container.classList.remove(this.errorClass);
        return true;
    } else {
        this.container.classList.add(this.errorClass);
        return false;
    }
}

FormInput.prototype.validateNumber = function() {
    if (!this.isInputVisible()) return true;

    var val = this.input.value;
    var reg = /^\d+$/;

    if (val.length && reg.test(val)) {
        this.container.classList.remove(this.errorClass);
        return true;
    } else {
        this.container.classList.add(this.errorClass);
        return false;
    }
}