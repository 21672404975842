/*
<a href="#sect-1" class="js-scroller">Section 1</a>
<section id="sect-1">
    <h2>Section 1</h2>
    <p>Pellentesque habitant morbi tristique senectus et netus et <a href="http://www.example.net/">a link to another page</a> ac turpis egestas. <a href="http://www.example.net/index.html#foo">A link to another page, with an anchor</a> quam, feugiat vitae, ...</p>
    <a href="#toc">Back to TOC</a>
</section>
*/

// ========================================================
// Scroller
// ========================================================
var Scroller = function(node) {
	this.link = node;
	this.container = document.querySelector(this.link.getAttribute('href'));
	this.link.addEventListener('click', this.click.bind(this), false);
}

Scroller.prototype.click = function(e) {
	e.preventDefault();
	var start = window.pageYOffset;
	var timeStart;
    var timeElapsed;
    var duration = 400;
    var distance = this.container.getBoundingClientRect().top;

    requestAnimationFrame(function(time) { 
    	timeStart = time; 
    	loop(time); 
    });

    function loop(time) {
        timeElapsed = time - timeStart;
        window.scrollTo(0, easeInOutQuad(timeElapsed, start, distance, duration));

        if (timeElapsed < duration) {
            requestAnimationFrame(loop);
        }
    }

    // Robert Penner's easeInOutQuad - http://robertpenner.com/easing/
    function easeInOutQuad(t, b, c, d)  {
        t /= d / 2
        if(t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
    }
}