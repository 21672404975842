// ========================================================
// SCROLL TABLE
// ========================================================

var ScrollTable = function(node, settings) {
	this.settings = extend({}, this.defaultSettings, settings);
	this.node = node;
	this.tableContainer = this.node.querySelector(this.settings.tableContainer);
	this.table = this.tableContainer.querySelector('table');
	this.btnLeft = this.node.querySelector(this.settings.btnLeft);
	this.btnRight = this.node.querySelector(this.settings.btnRight);
	this.position = 0;

	window.addEventListener('resize', this.resize.bind(this));
	this.btnLeft.addEventListener('click', this.slideBack.bind(this));
	this.btnRight.addEventListener('click', this.slideForward.bind(this));
	this.tableContainer.addEventListener('scroll', this.checkVisibilty.bind(this));

	this.checkVisibilty();
}

ScrollTable.prototype.defaultSettings = {
	'btnLeft': '.js-scrollLeft',
	'btnRight': '.js-scrollRight',
	'tableContainer': '.scroll-x'
}

ScrollTable.prototype.resize = function() {
	this.checkVisibilty();
}

ScrollTable.prototype.checkVisibilty = function() {

	if (this.table.clientWidth > this.tableContainer.clientWidth) {
		this.btnLeft.classList.add('btn-visible');
		this.btnRight.classList.add('btn-visible');
	} else {
		this.btnLeft.classList.remove('btn-visible');
		this.btnRight.classList.remove('btn-visible');
	}

	if (this.tableContainer.scrollLeft <= 0) {
		this.btnLeft.classList.remove('btn-active');
	} else {
		this.btnLeft.classList.add('btn-active');
	}

	
	if (this.tableContainer.scrollLeft + this.tableContainer.clientWidth >= this.table.clientWidth) {
		this.btnRight.classList.remove('btn-active');
	} else {
		this.btnRight.classList.add('btn-active');
	}
}

ScrollTable.prototype.slideBack = function () {
	this.position = -100;
	this.slide();
}

ScrollTable.prototype.slideForward = function () {
	this.position = 100;
	this.slide();
}

ScrollTable.prototype.slide = function() {
	var container = this.tableContainer;
	var start = this.tableContainer.scrollLeft;
	var timeStart;
    var timeElapsed;
    var distance = this.position;
    var duration = 400;

	requestAnimationFrame(function(time) { 
		timeStart = time; 
		loop(time); 
	});

	function loop(time) {
		timeElapsed = time - timeStart;
		container.scrollLeft = easeInOutQuad(timeElapsed, start, distance, duration);
		if (timeElapsed < duration) {
			requestAnimationFrame(loop);
		}
	}

    // Robert Penner's easeInOutQuad - http://robertpenner.com/easing/
    function easeInOutQuad(t, b, c, d)  {
    	t /= d / 2
    	if(t < 1) return c / 2 * t * t + b
    		t--
    	return -c / 2 * (t * (t - 2) - 1) + b
    }
}