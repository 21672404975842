// ========================================================
// Tabs
// ========================================================
var Tabs = function(node, settings) {
	this.settings = extend({}, this.defaultSettings, settings);
	this.container = node;
	this.list = this.container.querySelector(this.settings.list);
	this.content = this.container.querySelectorAll(this.settings.content);
	this.useAjax = this.content.length === 1;
	this.tabs = [];

	var tabs = this.container.querySelectorAll('.tabs__link');
	for (var i = 0; i < tabs.length; i++) {
		this.tabs.push(new Tab(tabs[i], i, this));
	}
}

Tabs.prototype.defaultSettings = {
	'activeClass': 'tabs__link--active',
	'list': '.tabs__list',
	'content': '.tabs__content'
}

Tabs.prototype.showContent = function(html) {
	this.content[0].innerHTML = html;
	lazyloadImages(this.content[0]);
}

var Tab = function(node, index, parent) {
	this.node = node;
	this.index = index;
	var tabs = parent;
	var showContent = tabs.showContent.bind(tabs);

	node.addEventListener('click', function(e) {
		if (tabs.useAjax) {		
			e.preventDefault();
			ajax({
				'url': node.href,
				'method': 'POST',
				'success': function(data) {
					showContent(data);
				},
				'error': function() {
					console.log('error');
				}
			});
		}
		
		tabs.currentTab.node.classList.remove(tabs.settings.activeClass);
		node.classList.add(tabs.settings.activeClass);
		tabs.currentTab = this;
	}.bind(this));

	if (node.classList.contains(tabs.settings.activeClass)) {
		tabs.currentTab = this;
	}
}