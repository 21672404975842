/*
@HTML
<div class="range js-range" data-min="0" data-max="180" data-setMin="10" data-setMax="100">
	<input type="hidden">
    <div class="range__track"></div>
    <div class="range__thumb range__thumb--min" tabindex="0">0</div>
    <div class="range__thumb range__thumb--max" tabindex="0">180</div>
</div>

@CSS
.range {
    width: 100%;
    background-color: gray;
    position: relative;
    height: 2px;
    cursor: pointer;
}

.range__track {
    position: relative;
    background-color: red;
    height: 2px;
}

.range__thumb {
    border-radius: 100%;
    width: 15px;
    height: 15px;
    background-color: black;
    position: absolute;
    top: 0;
    transform: translateY(-50%);

    &:hover,
    &:focus,
    &:active {
        background-color: blue;
    }
}
*/

// ========================================================
// Range selector
// ========================================================
var Range = function(node, settings) {
	this.settings = extend({}, this.defaultSettings, settings);
	this.container = node;
	this.track = this.container.querySelector(this.settings.track);
	this.dragThumbMin = this.container.querySelector(this.settings.thumbMinClass);
	this.dragThumbMax = this.container.querySelector(this.settings.thumbMaxClass);
	this.dragThumb = this.dragThumbMin;
	this.inputHidden = this.container.querySelector(this.settings.inputHidden);

	this.valMin = parseInt(this.container.getAttribute('data-min'));
	this.valMax = parseInt(this.container.getAttribute('data-max'));

	var startPosMin = this.container.getAttribute('data-setMin');
	var startPosMax = this.container.getAttribute('data-setMax');

	startPosMin = startPosMin ? startPosMin / this.valMax : 0.1;
	startPosMax = startPosMax ? startPosMax / this.valMax : 0.8;

	//console.log(startPosMax === true);
	//console.log(startPosMax / this.valMax);

	var resize = this.resize.bind(this);
	var click = this.click.bind(this);

	this.setBounds();

	this.dragThumbMin = new RangeDragThumb(this.dragThumbMin, {
		'startPos': startPosMin,
		'keyStep': 0.05,
		'type': 'min'
	}, this);

	this.dragThumbMax = new RangeDragThumb(this.dragThumbMax, {
		'startPos': startPosMax,
		'keyStep': 0.05,
		'type': 'max'
	}, this);

	console.log(this.settings);

	window.addEventListener('resize', resize, false);
	this.container.addEventListener('click', click);
	this.event = document.createEvent('HTMLEvents');
	this.event.initEvent('change', true, false);

	/* XXX Used to delay the change event for KnockOutJS */
	setTimeout(this.stretchTrack.bind(this), 0);
}

Range.prototype.defaultSettings = {
	'thumbMinClass': '.range__thumb--min',
	'thumbMaxClass': '.range__thumb--max',
	'track': '.range__track',
    'valueInput': '.range__value',
    'inputHidden': 'input[type="hidden"]',
	'onInit': function() {},
	'onChangeMin': function() {},
	'onChangeMax': function() {}
}

Range.prototype.click = function(e) {
	var offset = (e.pageX - this.container.getBoundingClientRect().left) / this.containerW;
	var arr = [this.dragThumbMin, this.dragThumbMax];

    var curr = arr[0];
    var diff = Math.abs(offset - curr.x);
    for (var val = 0; val < arr.length; val++) {
        var newdiff = Math.abs(offset - arr[val].x);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
        }
    }

    curr.x = offset - (this.dragThumbW / 2);
    curr.end();
    curr.move();
}

Range.prototype.resize = function() {
	if (this.resizeTimer) clearTimeout(this.resizeTimer);
	this.resizeTimer = setTimeout(function() {
		this.setBounds();
	}.bind(this), 100);
}

Range.prototype.setBounds = function() {
    this.containerW = this.container.offsetWidth;
    this.dragThumbW = this.dragThumb.offsetWidth / this.containerW;
}

Range.prototype.stretchTrack = function() {
	this.track.style.left = (this.dragThumbMin.x * 100) + '%';
	this.track.style.width = ((1 - ((1 - this.dragThumbMax.x) + this.dragThumbMin.x)) * 100) + '%';
	this.inputHidden.value = [this.dragThumbMin.value, this.dragThumbMax.value];
	this.inputHidden.dispatchEvent(this.event);
}


/*
==========
Drag thumb
==========
*/
var RangeDragThumb = function(node, settings, parent) {
	this.node = node;
	this.settings = settings;
	this.range = parent;
    this.valueInput = this.node.querySelector(this.range.settings.valueInput);
	this.x = settings.startPos;
	this.keyStep = settings.keyStep || 0.05;
	this.movingClass = 'range__thumb--moving';

	var pan = new Hammer(this.node);
	var move = this.move.bind(this);
	var end = this.end.bind(this);
	var focus = this.focus.bind(this);
	var blur = this.blur.bind(this);
	this.keyup = this.keyup.bind(this);

	var value = settings.type === 'min' ? Math.round(((this.range.valMax - this.range.valMin) * this.x) + this.range.valMin) :
		Math.round(((this.range.valMax - this.range.valMin) * (this.x + this.range.dragThumbW)) + this.range.valMin)

	pan.on('pan', move);
	pan.on('panend', end);

	this.state = {
		'node': this.node,
		'x': this.x,
		'value': value,
		'move': move,
		'end': end
	}

	this.node.addEventListener('focus', focus, false);
	this.node.addEventListener('blur', blur, false);

	this.node.style.left = (this.x * 100) +'%';
    this.valueInput.innerHTML = this.state.value;
	return this.state;
}

RangeDragThumb.prototype.move = function(e) {
	this.node.classList.add(this.movingClass);

	if (e) {
		this.state.x = Math.max(0, Math.min(1 - this.range.dragThumbW, (e.deltaX / this.range.containerW + this.x)));
	} else {
		this.state.x = Math.max(0, Math.min(1 - this.range.dragThumbW, this.x));
	}

	if (this.settings.type === 'min') {
		this.state.x = Math.min(this.state.x, this.range.dragThumbMax.x - this.range.dragThumbW);
        this.state.value = Math.round(((this.range.valMax - this.range.valMin) * this.state.x) + this.range.valMin);
		this.range.settings.onChangeMin.apply(this.state, arguments);
	} else if (this.settings.type === 'max') {
		this.state.x = Math.max(this.range.dragThumbMin.x + this.range.dragThumbW, this.state.x);
        this.state.value = Math.round(((this.range.valMax - this.range.valMin) * (this.state.x + this.range.dragThumbW)) + this.range.valMin);
		this.range.settings.onChangeMax.apply(this.state, arguments);
	}

	this.node.style.left = (this.state.x * 100) +'%';
    this.valueInput.innerHTML = this.state.value;
	this.range.stretchTrack();

	if (!e) {
		this.end();
	}
}

RangeDragThumb.prototype.end = function(e) {
	this.x = this.state.x;
	this.node.classList.remove(this.movingClass);
}

RangeDragThumb.prototype.focus = function() {
	document.addEventListener('keyup', this.keyup, false);
}

RangeDragThumb.prototype.blur = function() {
	document.removeEventListener('keyup', this.keyup, false);
}

RangeDragThumb.prototype.keyup = function(e) {
	switch(e.code) {
		case 'ArrowRight':
			this.x += this.keyStep;
			break;
		case 'ArrowLeft':
			this.x -= this.keyStep;
			break;
	}
	this.move();
}
