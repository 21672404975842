// ========================================================
// Accordion
// ========================================================
var Accordion = function(node, settings) {
	this.settings = extend({}, this.defaultSettings, settings);
	this.container = node;
	this.trigger = this.container.querySelector(this.settings.trigger);
	this.content = this.container.querySelector(this.settings.content);

	this.resize = this.resize.bind(this);
	this.toggle = this.toggle.bind(this);
	this.isOpen = this.container.classList.contains(this.settings.activeClass);

	if (!this.settings.destroyOn) {
		this.init();
	}

	window.addEventListener('resize', this.resize);
	this.resize();
}

Accordion.prototype.defaultSettings = {
	'trigger': '.accordion__trigger',
	'content': '.accordion__content',
	'activeClass': 'accordion--active',
	'destroyOn': 0
}

Accordion.prototype.init = function() {
	this.trigger.addEventListener('click', this.toggle);
	this.container.classList.remove('accordion--is-destroyed');
}

Accordion.prototype.destroy = function() {
	this.trigger.removeEventListener('click', this.toggle);
	this.container.classList.add('accordion--is-destroyed');
}

Accordion.prototype.resize = function() {
	if (this.isOpen) {
		this.content.style.maxHeight = this.content.scrollHeight + 'px';
	}

	if (this.settings.destroyOn) {
		if (window.innerWidth <= this.settings.destroyOn) {
			this.init();
		} else {
			this.destroy();
		}
	}
}

Accordion.prototype.toggle = function(e) {
	if (e) e.preventDefault();
	this.container.classList.toggle(this.settings.activeClass);
	this.isOpen = !this.isOpen;
	this.setHeight();
}

Accordion.prototype.setHeight = function() {
	if (!this.isOpen) {
		this.content.style.maxHeight = '';
		this.content.style.overflow = '';
	} else {
		setTimeout(function() {
			this.content.style.overflow = 'visible';
		}.bind(this), 400);
		this.content.style.maxHeight = this.content.scrollHeight + 'px';
	}
}