'use strict'
// ========================================================
// ON READY
// ========================================================
document.addEventListener('DOMContentLoaded', function() {
	var i, l, selector;
	var carousel3d = [];


	/* Autoscroll */
	selector = document.querySelectorAll('.js-scroller');
	for (i = 0, l = selector.length; i < l; i++) {
		new Scroller(selector[i]);
	}

	/* Scrolltable */
	selector = document.querySelectorAll('.js-scrollTable');
	for (i = 0, l = selector.length; i < l; i++) {
		new ScrollTable(selector[i]);
	}

	var country = document.querySelector('.menu .country');
	var countryCheckbox = country.querySelector('input');
	if (country && countryCheckbox) {
		countryCheckbox.addEventListener('change', function() {
			if (countryCheckbox.checked) {
				Modal(country, {
					'onClose': function() {
						countryCheckbox.checked = false;
					}
				}).open();
			} else {
				Modal(country).close();
			}
		});
	}

	var countryBackground = document.querySelector('.background-menu .country');
	var countryBackgroundCheckbox = countryBackground.querySelector('input');
	if (countryBackground && countryBackgroundCheckbox) {
		countryBackgroundCheckbox.addEventListener('change', function() {
			if (countryBackgroundCheckbox.checked) {
				Modal(countryBackground, {
					'onClose': function() {
						countryBackgroundCheckbox.checked = false;
					}
				}).open();
			} else {
				Modal(countryBackground).close();
			}
		});
	}

	/* If user has scrolled 200px down */
	window.addEventListener('scroll', userScrolled);
	function userScrolled() {
		if (window.pageYOffset > 200) {
			document.body.classList.add('user-scrolled');
		} else {
			document.body.classList.remove('user-scrolled');
		}		
	}
	if (window.pageYOffset > 200) {
		document.body.classList.add('user-scrolled');
	} else {
		document.body.classList.remove('user-scrolled');
	}


	/* Form validator */
	selector = document.querySelectorAll('.js-form');
	for (i = 0, l = selector.length; i < l; i++) {
		new FormValidate(selector[i]);
	}

	/* Form range */
	selector = document.querySelectorAll('.js-range');
	for (i = 0, l = selector.length; i < l; i++) {
		new Range(selector[i], {
			'onChangeMin': function() {
				//console.log(this.value);
			},
			'onChangeMax': function() {
				//console.log(this.value);
			}
		});
	}

	/* Form floating Label */
	selector = document.querySelectorAll('.js-floating-label'); 
	for (i = 0, l = selector.length; i < l; i++) {
		new FloatingLabel(selector[i]);
	}


	/* Paralax */
	var paralax = document.querySelectorAll('.js-paralax');
	for (i = 0, l = paralax.length; i < l; i++) {
		new Paralax(paralax[i]);
	}



	/* Tabs */
	selector = document.querySelectorAll('.js-tabs');
	for (i = 0, l = selector.length; i < l; i++) {
		new Tabs(selector[i]);
	}


	/* Table slider */
	selector = document.querySelectorAll('.js-carousel-slider');
	for (i = 0, l = selector.length; i < l; i++) {
		new CarouselSlider(selector[i], {
			'list': '.carousel-list'
		});
	}

	/* lightbox - Product zoom */
	var lightboxProduct = document.querySelectorAll('.js-lightbox-product');
	for (i = 0, l = lightboxProduct.length; i < l; i++) {
		new Lightbox(lightboxProduct[i], {
			'trigger': '.js-lightbox-product-trigger'
		});
	}

	/* lightbox - Video */
	var lightboxVideo = document.querySelectorAll('.js-lightbox-video');
	for (i = 0, l = lightboxVideo.length; i < l; i++) {
		new Lightbox(lightboxVideo[i], {
			'trigger': '.js-lightbox-video-trigger',
			'onOpen': function() {				
				this.container.querySelector('iframe').src = this.triggerClicked.href + '?rel=0&autoplay=1&wmode=opaque&fs=1';
			},
			'onClose': function() {
				this.container.querySelector('iframe').src = '';
			}
		});
	}


	/* Accordions */
	selector = document.querySelectorAll('.js-accordion');
	for (i = 0, l = selector.length; i < l; i++) {
		new Accordion(selector[i]);
	}

	selector = document.querySelectorAll('.js-accordion-responsive');
	for (i = 0, l = selector.length; i < l; i++) {
		new Accordion(selector[i], {
			'destroyOn': 960
		});
	}

	/* Carousel */
	for (var i = 0, carousel = document.querySelectorAll('.js-carousel'); i < carousel.length; i++) {
		carousel3d.push(new Carousel(carousel[i], {
				'changeDuration': 1400,
				'onBeforeChange': function() {
					var previousTl = new TimelineMax();			
					var previousSlide = this.previousSlide.node;
					var previousImage1 = previousSlide.querySelector('.carousel__images--1');
					//var previousImage2 = previousSlide.querySelector('.carousel__images--2');
					var previousText = previousSlide.querySelector('.carousel__text');

					previousTl
						.to(previousText, .2, { autoAlpha: 0 })						
						//.to(previousImage2, .2, { autoAlpha: 0 })
						.to(previousImage1, .2, { autoAlpha: 0 });
				},
				'onChangeComplete': function() {
					var currentTl = new TimelineMax();
					var currentSlide = this.currentSlide.node;
					var images = currentSlide.querySelector('.carousel__images');
					var image1 = currentSlide.querySelector('.carousel__images--1');
					//var image2 = currentSlide.querySelector('.carousel__images--2');
					var text = currentSlide.querySelector('.carousel__text');

					currentTl
						.fromTo(text, .4, 
							{ y: -150, autoAlpha: 0 },
							{ y: 0, autoAlpha: 1 })
									 
						/*.fromTo(image2, .2, 
						  	{ scale: 1.1, autoAlpha: 0, x: 0, y: -50, rotation: -1 }, 
						  	{ scale: 1, autoAlpha: 1, x: -10, y: -15, rotation: -1 })*/

						.fromTo(image1, .2, 
						  	{ autoAlpha: 0 }, 
						  	{ autoAlpha: 1 })

						.fromTo(image1, .8, 
						  	{ scale: 1.6, rotation: 30 }, 
						  	{ scale: 1, rotation: 0, ease: Bounce.easeOut}, '-=.2');
					
				}
			})
		);
	}


	/* 
		Navigation 
		dependency: modal.js
	*/
	Navigation = new Navigation({
		'onOpen': function() {
			if (this.type === 'main') {
				Modal(this.node, {
					'insideTrigger': false,
					'onClose': this.close.bind(this)
				}).open();
			}
		},
		'onClose': function() {
			Modal(this.node).close();
		}
	});

	new BackgroundMenu({
		'onOpen': function() {
			window.removeEventListener('scroll', userScrolled);
			if (carousel3d.length) {
				for (i = 0; i < carousel3d.length; i++) {
					carousel3d[i].autoPlayPause();
				}
			}
		},
		'onClose': function() {
			window.addEventListener('scroll', userScrolled);
		}
	});

}, false);